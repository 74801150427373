#root .circle {
  width: 90px;
  height: 90px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: var(--mint800);
  border-radius: 50%;
}
#root .circle img {
  width: 54px;
  height: 54px;
}
#root .procedure-page {
  padding-top: 60px;
}
#root .procedure-page > .card-company-section > .card-company-wrapper {
  padding-top: 104px;
  padding-bottom: 234px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  row-gap: 46px;
}
#root .procedure-page > .card-company-section > .card-company-wrapper > .title-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  row-gap: 17px;
}
#root .procedure-page > .card-company-section > .card-company-wrapper > .title-wrapper > .title-container > .title {
  white-space: pre-wrap;
  line-height: 47.6px;
  font-weight: 800;
  font-size: 34px;
  text-align: center;
}
#root .procedure-page > .card-company-section > .card-company-wrapper > .card-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 60px;
     -moz-column-gap: 60px;
          column-gap: 60px;
}
#root .procedure-page > .card-company-section > .card-company-wrapper > .card-container .card:nth-child(1) > .sub-title br:nth-child(1),
#root .procedure-page > .card-company-section > .card-company-wrapper > .card-container .card:nth-child(1) > .sub-title br:nth-child(3) {
  display: none;
}
#root .procedure-page > .card-company-section > .card-company-wrapper > .card-container .card:nth-child(5) br:nth-child(2),
#root .procedure-page > .card-company-section > .card-company-wrapper > .card-container .card:nth-child(5) br:nth-child(4) {
  display: none;
}
#root .procedure-page > .card-company-section > .card-company-wrapper > .card-container > .card {
  width: 282px;
  height: 318px;
  border-radius: 5px;
  background-color: white;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}
#root .procedure-page > .card-company-section > .card-company-wrapper > .card-container > .card > .step-box {
  width: 282px;
  height: 182px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  row-gap: 10px;
  background: linear-gradient(150deg, #00DCAB 0%, #05D5AA 45%, #3D849C 100%);
}
#root .procedure-page > .card-company-section > .card-company-wrapper > .card-container > .card > .step-box > .step-number {
  color: white;
  font-size: 13px;
  font-weight: 700;
}
#root .procedure-page > .card-company-section > .card-company-wrapper > .card-container > .card > .step-box > .step-text {
  color: white;
  font-size: 26px;
  font-weight: 700;
  line-height: 140%;
  white-space: pre-line;
  text-align: center;
}
#root .procedure-page > .card-company-section > .card-company-wrapper > .card-container > .card > .sub-title {
  font-size: 20px;
  text-align: center;
  line-height: 150%;
  padding-top: 26px;
}
#root .procedure-page > .card-company-section > .card-company-wrapper > .card-container img {
  width: 47px;
  height: 47px;
}
#root .procedure-page > .card-company-section > .card-company-wrapper > .kakaotalk-button {
  width: 328px;
  height: 50px;
  font-size: 16px;
  font-weight: 700;
  background-color: black;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 5px;
}
#root .procedure-page > .store-program-section {
  background-color: var(--grey700);
}
#root .procedure-page > .store-program-section > .store-program-wrapper {
  width: 100%;
  padding: 142px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-column-gap: 69px;
     -moz-column-gap: 69px;
          column-gap: 69px;
  row-gap: 42px;
  position: relative;
}
#root .procedure-page > .store-program-section > .store-program-wrapper > .title-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-column-gap: 52px;
     -moz-column-gap: 52px;
          column-gap: 52px;
  row-gap: 16px;
}
#root .procedure-page > .store-program-section > .store-program-wrapper > .title-container > .title {
  font-size: 34px;
  font-weight: 800;
  line-height: 47.6px;
  white-space: pre-wrap;
  color: white;
}
#root .procedure-page > .store-program-section > .store-program-wrapper > .title-container > .bubble {
  margin-top: 44px;
  position: relative;
  width: 491px;
  height: 125px;
  z-index: 1;
  padding: 0px;
  background: #FFFFFF;
  border-radius: 10px;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .procedure-page > .store-program-section > .store-program-wrapper > .title-container > .bubble .color {
  color: var(--mint800);
}
#root .procedure-page > .store-program-section > .store-program-wrapper > .title-container .bubble:after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 0 15px 15px;
  border-color: #FFFFFF transparent;
  display: block;
  width: 0;
  z-index: 1;
  top: -14px;
  left: 230px;
}
#root .procedure-page > .store-program-section > .store-program-wrapper > .img-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#root .procedure-page > .store-program-section > .store-program-wrapper > .img-container > .pos-img {
  width: 491px;
  height: 378px;
  position: relative;
  z-index: 1;
}
#root .procedure-page > .store-program-section > .store-program-wrapper > .img-container > .cloud-img {
  width: 491px;
  height: 378px;
  position: absolute;
  bottom: 0;
  right: -50px;
}

@media screen and (max-width: 1439px) {
  #root .procedure-page .card-company-section .card-company-wrapper {
    padding: 100px 36px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  #root .procedure-page .card-company-section .card-company-wrapper .card-container {
    -webkit-column-gap: 20px;
       -moz-column-gap: 20px;
            column-gap: 20px;
  }
  #root .procedure-page .card-company-section .card-company-wrapper .card-container .card:nth-child(1) .sub-title br:nth-child(1) {
    display: block;
  }
  #root .procedure-page .card-company-section .card-company-wrapper .card-container .card:nth-child(1) .sub-title br:nth-child(3) {
    display: block;
  }
  #root .procedure-page .card-company-section .card-company-wrapper .card-container .card:nth-child(5) .sub-title br:nth-child(2) {
    display: block;
  }
  #root .procedure-page .card-company-section .card-company-wrapper .card-container .card:nth-child(5) .sub-title br:nth-child(3) {
    display: none;
  }
  #root .procedure-page .card-company-section .card-company-wrapper .card-container .card:nth-child(5) .sub-title br:nth-child(4) {
    display: block;
  }
  #root .procedure-page .card-company-section .card-company-wrapper .card-container .card {
    width: 219px;
    height: 363px;
  }
  #root .procedure-page .card-company-section .card-company-wrapper .card-container .card .step-box {
    width: 219px;
  }
  #root .procedure-page .card-company-section .card-company-wrapper .card-container .card .sub-title {
    padding-top: 34px;
  }
  #root .procedure-page .card-company-section .card-company-wrapper .card-container .arrow {
    display: none;
  }
  #root .procedure-page .store-program-section .store-program-wrapper {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-bottom: 200px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  #root .procedure-page .store-program-section .store-program-wrapper .title-container {
    text-align: center;
  }
  #root .procedure-page .store-program-section .store-program-wrapper .title-container .circle {
    margin: 0 auto;
  }
  #root .procedure-page .store-program-section .store-program-wrapper .title-container .bubble {
    position: absolute;
    bottom: 50px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}
@media screen and (max-width: 767px) {
  #root .procedure-page .card-company-section .card-company-wrapper {
    padding: 80px 16px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  #root .procedure-page .card-company-section .card-company-wrapper .title-wrapper .title-container > .title {
    font-size: 26px;
    font-weight: 700;
    line-height: 140%;
  }
  #root .procedure-page .card-company-section .card-company-wrapper .card-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 20px;
  }
  #root .procedure-page .card-company-section .card-company-wrapper .card-container .card:nth-child(1) .sub-title br:nth-child(1),
  #root .procedure-page .card-company-section .card-company-wrapper .card-container .card:nth-child(1) .sub-title br:nth-child(3) {
    display: none;
  }
  #root .procedure-page .card-company-section .card-company-wrapper .card-container .card:nth-child(5) .sub-title br:nth-child(2),
  #root .procedure-page .card-company-section .card-company-wrapper .card-container .card:nth-child(5) .sub-title br:nth-child(4) {
    display: none;
  }
  #root .procedure-page .card-company-section .card-company-wrapper .card-container .card:nth-child(5) .sub-title br:nth-child(3) {
    display: block;
  }
  #root .procedure-page .card-company-section .card-company-wrapper .card-container .card {
    width: 328px;
    height: 272px;
  }
  #root .procedure-page .card-company-section .card-company-wrapper .card-container .card .step-box {
    width: 328px;
    height: 136px;
  }
  #root .procedure-page .card-company-section .card-company-wrapper .card-container .card .step-box .step-text {
    font-size: 24px;
    line-height: 150%;
  }
  #root .procedure-page .card-company-section .card-company-wrapper .card-container .card .sub-title {
    font-size: 16px;
    padding-top: 22px;
  }
  #root .procedure-page .store-program-section .store-program-wrapper {
    padding-top: 80px;
    padding-left: 14px;
    padding-right: 14px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  #root .procedure-page .store-program-section .store-program-wrapper .title-container .title {
    font-size: 26px;
    line-height: 36.4px;
  }
  #root .procedure-page .store-program-section .store-program-wrapper .title-container .bubble {
    width: 328px;
    height: 125px;
    font-size: 16px;
    line-height: 26px;
  }
  #root .procedure-page .store-program-section .store-program-wrapper .title-container .bubble:after {
    left: 150px;
  }
  #root .procedure-page .store-program-section .store-program-wrapper .img-container .pos-img {
    width: 306px;
    height: 236px;
  }
  #root .procedure-page .store-program-section .store-program-wrapper .img-container .cloud-img {
    width: 256px;
    height: 185px;
  }
}