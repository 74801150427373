#root .card-terminal-section {
  padding: 120px 0px 154px 0px;
  background-color: var(--grey100);
}
#root .card-terminal-section > .card-terminal-wrapper > .title-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .card-terminal-section > .card-terminal-wrapper > .title-container > .color-text {
  color: var(--main);
  font-size: 18px;
  font-weight: 700;
}
#root .card-terminal-section > .card-terminal-wrapper > .title-container > .bold-text {
  font-size: 34px;
  font-weight: 800;
  line-height: 47.6px;
  text-align: center;
}
#root .card-terminal-section > .card-terminal-wrapper > .terminal-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 30px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 43px;
}
#root .card-terminal-section > .card-terminal-wrapper > .terminal-container > .terminal {
  width: 1086px;
  height: 289px;
  padding: 30px 63px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 20px;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-column-gap: 131px;
     -moz-column-gap: 131px;
          column-gap: 131px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}
#root .card-terminal-section > .card-terminal-wrapper > .terminal-container > .terminal > .img-wrapper {
  width: 265px;
  height: 220px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .card-terminal-section > .card-terminal-wrapper > .terminal-container > .terminal > .img-wrapper > .terminal-img {
  width: 265px;
  height: 220px;
}
#root .card-terminal-section > .card-terminal-wrapper > .terminal-container > .terminal > .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 50px;
}
#root .card-terminal-section > .card-terminal-wrapper > .terminal-container > .terminal > .container > .terminal-title-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 16px;
}
#root .card-terminal-section > .card-terminal-wrapper > .terminal-container > .terminal > .container > .terminal-title-container > .terminal-title {
  font-size: 24px;
  font-weight: 700;
}
#root .card-terminal-section > .card-terminal-wrapper > .terminal-container > .terminal > .container > .terminal-title-container > .terminal-title br:nth-child(2) {
  display: none;
}
#root .card-terminal-section > .card-terminal-wrapper > .terminal-container > .terminal > .container > .terminal-title-container > .terminal-subtitle {
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
}
#root .card-terminal-section > .card-terminal-wrapper > .terminal-container > .terminal > .container > .store {
  width: 568px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .card-terminal-section > .card-terminal-wrapper > .terminal-container > .terminal > .container > .store > .price {
  font-size: 24px;
  font-weight: 700;
  margin-top: auto;
}
#root .card-terminal-section > .card-terminal-wrapper > .terminal-container > .terminal > .container > .store > .price span {
  margin-top: auto;
  padding-right: 5px;
  font-size: 14px;
  font-weight: 700;
  color: var(--main);
}
#root .card-terminal-section > .card-terminal-wrapper > .terminal-container > .terminal > .container > .store > .naver-store-button {
  width: 304px;
  height: 50px;
  background-color: #33C565;
  border-radius: 5px;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: white;
  font-size: 16px;
  font-weight: 700;
}
#root .category-section {
  padding-bottom: 199px;
  background-color: var(--blue50);
}
#root .category-section > .category-wrapper {
  padding-top: 136px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  row-gap: 67px;
}
#root .category-section > .category-wrapper > .category-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 67px;
}
#root .category-section > .category-wrapper > .category-container > .category-title-container > .category-subtitle {
  color: var(--main);
  font-weight: 700;
  font-size: 18px;
}
#root .category-section > .category-wrapper > .category-container > .category-title-container > .category-title {
  font-weight: 800;
  font-size: 24px;
  line-height: 47.6px;
  white-space: pre-wrap;
}
#root .category-section > .category-wrapper > .category-container > .category-detail-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-column-gap: 26px;
     -moz-column-gap: 26px;
          column-gap: 26px;
}
#root .category-section > .category-wrapper > .category-container > .category-detail-wrapper > .category-menu-bar > .menubar-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 10px;
}
#root .category-section > .category-wrapper > .category-container > .category-detail-wrapper > .category-menu-bar > .menubar-container > .category-box {
  width: 146px;
  border-radius: 50px;
  padding: 10px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: white;
  font-size: 20px;
  font-weight: 700;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
#root .category-section > .category-wrapper > .category-container > .category-detail-wrapper > .category-menu-bar > .menubar-container .now-category-box {
  background-color: var(--main);
  color: white;
}
#root .category-section > .category-wrapper > .category-container > .category-detail-wrapper > .category-detail-card-container {
  min-width: 914px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
#root .category-section > .category-wrapper > .category-container > .category-detail-wrapper > .category-detail-card-container > .category-detail-box {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 294px;
  height: 320px;
  padding: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 16px;
  background-color: white;
  border-radius: 5px;
}
#root .category-section > .category-wrapper > .category-container > .category-detail-wrapper > .category-detail-card-container > .category-detail-box > .category-detail-title-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
#root .category-section > .category-wrapper > .category-container > .category-detail-wrapper > .category-detail-card-container > .category-detail-box > .category-detail-title-container > .color-text {
  color: var(--main);
  font-size: 13px;
  font-weight: 700;
}
#root .category-section > .category-wrapper > .category-container > .category-detail-wrapper > .category-detail-card-container > .category-detail-box > .category-detail-title-container > .detail-text {
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
  margin: 3px 0 11px;
  min-height: 48px;
}
#root .category-section > .category-wrapper > .category-container > .category-detail-wrapper > .category-detail-card-container > .category-detail-box > .category-detail-title-container > .detail-subtext {
  font-size: 12px;
}

@media screen and (max-width: 1439px) {
  #root .skill-page > .card-terminal-section .terminal-container .terminal {
    width: 688px;
    height: 259px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 30px 40px;
    -webkit-column-gap: 40px;
       -moz-column-gap: 40px;
            column-gap: 40px;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  #root .skill-page > .card-terminal-section .terminal-container .terminal > .img-wrapper {
    width: 199px;
    height: 165px;
  }
  #root .skill-page > .card-terminal-section .terminal-container .terminal > .img-wrapper .terminal-img {
    width: 199px;
    height: 165px;
  }
  #root .skill-page > .card-terminal-section .terminal-container .terminal .terminal-title-container .terminal-title br {
    display: none;
  }
  #root .skill-page > .card-terminal-section .terminal-container .terminal .container {
    row-gap: 0px;
    height: 100%;
  }
  #root .skill-page > .card-terminal-section .terminal-container .terminal .container .store {
    width: auto;
    margin-top: auto;
    -webkit-column-gap: 20px;
       -moz-column-gap: 20px;
            column-gap: 20px;
    -webkit-box-pack: auto;
        -ms-flex-pack: auto;
            justify-content: auto;
  }
  #root .skill-page > .card-terminal-section .terminal-container .terminal .container .store > .price {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    line-height: 150%;
    /* 18px */
  }
  #root .skill-page > .card-terminal-section .terminal-container .terminal .container .store > .price span {
    display: block;
  }
  #root .skill-page > .card-terminal-section .terminal-container .terminal .container .store .naver-store-button {
    width: 221px;
    height: 50px;
    margin-top: auto;
  }
  #root .skill-page .category-section .category-wrapper .category-container {
    row-gap: 31px;
  }
  #root .skill-page .category-section .category-wrapper .category-container .category-detail-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    row-gap: 46px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  #root .skill-page .category-section .category-wrapper .category-container .category-detail-wrapper .category-menu-bar .menubar-container {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(4, 1fr);
  }
  #root .skill-page .category-section .category-wrapper .category-container .category-detail-wrapper .category-detail-card-container {
    min-width: 688px;
    grid-template-columns: repeat(2, 1fr);
  }
  #root .skill-page .category-section .category-wrapper .category-container .category-detail-wrapper .category-detail-card-container .category-detail-box {
    width: 334px;
    height: 364px;
  }
  #root .skill-page .category-section .category-wrapper .category-container .category-detail-wrapper .category-detail-card-container .category-detail-box img {
    width: 302px;
    height: 216px;
  }
}
@media screen and (max-width: 767px) {
  #root .skill-page .card-terminal-section {
    padding: 56px 16px 126px 16px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  #root .skill-page .card-terminal-section .terminal-container .terminal {
    width: 100%;
    max-width: 519px;
    height: 100%;
    padding: 24px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    row-gap: 24px;
  }
  #root .skill-page .card-terminal-section .terminal-container .terminal .terminal-title-container {
    text-align: center;
  }
  #root .skill-page .card-terminal-section .terminal-container .terminal .terminal-title-container .terminal-title {
    width: 280px;
    line-height: 150%;
  }
  #root .skill-page .card-terminal-section .terminal-container .terminal .terminal-title-container .terminal-title br:nth-child(1) {
    display: none;
  }
  #root .skill-page .card-terminal-section .terminal-container .terminal .terminal-title-container .terminal-title br:nth-child(1) {
    display: block;
  }
  #root .skill-page .card-terminal-section .terminal-container .terminal .container {
    row-gap: 24px;
  }
  #root .skill-page .card-terminal-section .terminal-container .terminal .container .terminal-title-container .terminal-subtitle {
    line-height: 150%;
  }
  #root .skill-page .card-terminal-section .terminal-container .terminal .container .store {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-column-gap: 40px;
       -moz-column-gap: 40px;
            column-gap: 40px;
    row-gap: 56px;
  }
  #root .skill-page .card-terminal-section .terminal-container .terminal .container .store .price {
    text-align: center;
    row-gap: 10px;
    line-height: normal;
  }
  #root .skill-page .category-section {
    padding-left: 16px;
    padding-right: 16px;
  }
  #root .skill-page .category-section .category-wrapper .category-title {
    line-height: 36.4px;
  }
  #root .skill-page .category-section .category-wrapper .category-container .category-title-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 10px;
    text-align: center;
  }
  #root .skill-page .category-section .category-wrapper .category-container .category-title-container .category-title {
    line-height: 36.4px;
  }
  #root .skill-page .category-section .category-wrapper .category-container .category-detail-wrapper .category-menu-bar .menubar-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    width: 340px;
    gap: 16px 8px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  #root .skill-page .category-section .category-wrapper .category-container .category-detail-wrapper .category-menu-bar .menubar-container .category-box {
    width: auto;
    height: auto;
    padding: 8px 16px;
    font-size: 14px;
  }
  #root .skill-page .category-section .category-wrapper .category-container .category-detail-wrapper .category-detail-card-container {
    min-width: 334px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  #root .skill-page .category-section .category-wrapper .category-container .category-detail-wrapper .category-detail-card-container .category-detail-box {
    width: 334px;
    height: 364px;
  }
}