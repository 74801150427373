#root .card-terminal-page > .store-pos-section > .store-pos-wrapper {
  padding-top: 130px;
  padding-bottom: 149px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-column-gap: 30px;
     -moz-column-gap: 30px;
          column-gap: 30px;
}
#root .card-terminal-page > .store-pos-section > .store-pos-wrapper > .pos-introduction {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 30px;
}
#root .card-terminal-page > .store-pos-section > .store-pos-wrapper > .pos-introduction > .introduction-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 10px;
}
#root .card-terminal-page > .store-pos-section > .store-pos-wrapper > .pos-introduction > .introduction-text > .color-text {
  color: var(--main);
  font-size: 18px;
  font-weight: 800;
}
#root .card-terminal-page > .store-pos-section > .store-pos-wrapper > .pos-introduction > .introduction-text > .title-text {
  font-size: 34px;
  font-weight: 800;
  line-height: 140%;
  white-space: pre-line;
}
#root .card-terminal-page > .store-pos-section > .store-pos-wrapper > .pos-introduction > .introduction-text > .sub-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 28.8px;
  white-space: pre-line;
}
#root .card-terminal-page > .store-pos-section > .store-pos-wrapper > .pos-introduction > .store {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 16px;
}
#root .card-terminal-page > .store-pos-section > .store-pos-wrapper > .pos-introduction > .store > .price {
  font-weight: 700;
  font-size: 25px;
}
#root .card-terminal-page > .store-pos-section > .store-pos-wrapper > .pos-introduction > .store > .price span {
  font-size: 13px;
}
#root .card-terminal-page > .store-pos-section > .store-pos-wrapper > .pos-introduction > .store > .naver-store-button {
  width: 304px;
  height: 50px;
  background-color: #33C565;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-weight: 700;
}
#root .card-terminal-page > .store-pos-section > .store-pos-wrapper > .pos-img-container {
  width: 420px;
  position: relative;
}
#root .card-terminal-page > .store-pos-section > .store-pos-wrapper > .pos-img-container img:nth-child(1) {
  position: absolute;
  z-index: 1;
}
#root .card-terminal-page > .store-pos-section > .store-pos-wrapper > .pos-img-container img:nth-child(2) {
  position: absolute;
  left: 0;
}
#root .card-terminal-page > .terminal-store-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: var(--grey100);
}
#root .card-terminal-page > .terminal-store-section > .terminal-store-wrapper {
  padding-top: 130px;
  padding-bottom: 97px;
}
#root .card-terminal-page > .terminal-store-section > .terminal-store-wrapper .store-button {
  width: 304px;
  height: 50px;
  font-size: 16px;
  color: white;
  font-weight: 700;
  background-color: var(--main);
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 auto;
  margin-top: 57px;
}
#root .card-terminal-page > .terminal-store-section > .terminal-store-wrapper .text-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 14px;
     -moz-column-gap: 14px;
          column-gap: 14px;
}
#root .card-terminal-page > .terminal-store-section > .terminal-store-wrapper .text-container > .logo-img {
  width: 90px;
  height: 90px;
  border-radius: 50px;
  background-color: var(--main);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .card-terminal-page > .terminal-store-section > .terminal-store-wrapper .text-container > .logo-img img {
  width: 60px;
  height: 60px;
}
#root .card-terminal-page > .terminal-store-section > .terminal-store-wrapper .text-container > .title-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 4px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .card-terminal-page > .terminal-store-section > .terminal-store-wrapper .text-container > .title-container > .color-text {
  color: var(--main);
  font-size: 18px;
  font-weight: 700;
}
#root .card-terminal-page > .terminal-store-section > .terminal-store-wrapper .text-container > .title-container > .title {
  font-weight: 800;
  font-size: 34px;
}
#root .card-terminal-page > .terminal-store-section > .terminal-store-wrapper > .terminal-choice-container:nth-child(1) {
  margin-bottom: 102px;
}
#root .card-terminal-page > .terminal-store-section > .terminal-store-wrapper > .terminal-choice-container:nth-child(1) > .terminal-card-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  -webkit-column-gap: 34px;
     -moz-column-gap: 34px;
          column-gap: 34px;
  row-gap: 36px;
  padding-top: 32px;
}
#root .card-terminal-page > .terminal-store-section > .terminal-store-wrapper > .terminal-choice-container:nth-child(1) > .terminal-card-container > .terminal-card {
  width: 302px;
  height: 302px;
  border-radius: 5px;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  row-gap: 32px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .card-terminal-page > .terminal-store-section > .terminal-store-wrapper > .terminal-choice-container:nth-child(1) > .terminal-card-container > .terminal-card img {
  width: 142.19px;
  height: 174.739px;
}
#root .card-terminal-page > .terminal-store-section > .terminal-store-wrapper > .terminal-choice-container:nth-child(1) > .terminal-card-container > .terminal-card p {
  font-weight: 700;
  font-size: 21px;
}
#root .card-terminal-page > .terminal-store-section > .terminal-store-wrapper > .terminal-choice-container:nth-child(2) > .terminal-card-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-column-gap: 20px;
     -moz-column-gap: 20px;
          column-gap: 20px;
  padding-top: 45px;
}
#root .card-terminal-page > .terminal-store-section > .terminal-store-wrapper > .terminal-choice-container:nth-child(2) > .terminal-card-container > .terminal-card {
  width: 227px;
  height: 227px;
  background-color: white;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .card-terminal-page > .terminal-store-section > .terminal-store-wrapper > .terminal-choice-container:nth-child(2) > .terminal-card-container > .terminal-card img {
  width: 184.737px;
  height: 147.036px;
}
#root .card-terminal-page > .terminal-store-section > .terminal-store-wrapper > .terminal-choice-container:nth-child(2) > .terminal-card-container > .terminal-card p {
  font-weight: 700;
  font-size: 21px;
}
#root .card-terminal-page > .terminal-store-section > .terminal-store-wrapper > .terminal-choice-container:nth-child(1) .terminal-card-container .terminal-card, #root .card-terminal-page > .terminal-store-section > .terminal-store-wrapper > .terminal-choice-container:nth-child(2) .terminal-card-container .terminal-card {
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}
#root .card-terminal-page > .question-section > .question-wrapper {
  background-color: var(--blue50);
  padding-top: 124px;
  padding-bottom: 145px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 26px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .card-terminal-page > .question-section > .question-wrapper > .text-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 8px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#root .card-terminal-page > .question-section > .question-wrapper > .text-container > .color-text {
  font-size: 18px;
  font-weight: 700;
  color: var(--main);
}
#root .card-terminal-page > .question-section > .question-wrapper > .text-container > .title-text {
  font-size: 34px;
  font-weight: 800;
}
#root .card-terminal-page > .question-section > .question-wrapper > .question-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#root .card-terminal-page > .question-section > .question-wrapper > .question-container > .question-card-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  justify-items: center;
}
#root .card-terminal-page > .question-section > .question-wrapper > .question-container > .question-card-container > .question-card {
  width: 539px;
  height: 94px;
  padding-left: 26px;
  background-color: white;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  line-height: 27px;
}
#root .card-terminal-page > .question-section > .question-wrapper > .question-container > .question-card-container > .question-card > .question {
  font-weight: 700;
  font-size: 18px;
}
#root .card-terminal-page > .question-section > .question-wrapper > .question-container > .question-card-container > .question-card > .answer {
  font-size: 18px;
  font-weight: 400;
}
#root .card-terminal-page > .question-section > .question-wrapper > .customer-inquiry-container {
  padding-top: 44px;
}
#root .card-terminal-page > .question-section > .question-wrapper > .customer-inquiry-container > .customer-inquiry-button {
  width: 304px;
  height: 50px;
  background-color: var(--main);
  padding: 0px 15px;
  color: white;
  border: none;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 16px;
  font-weight: 700;
}

@media screen and (max-width: 1439px) {
  #root .card-terminal-page > .store-pos-section {
    padding: 0px 40px;
  }
  #root .card-terminal-page > .store-pos-section > .store-pos-wrapper {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  #root .card-terminal-page > .store-pos-section > .store-pos-wrapper > .pos-img-container {
    width: 320px;
    height: 320px;
  }
  #root .card-terminal-page .terminal-store-section {
    padding: 0px 40px;
  }
  #root .card-terminal-page .terminal-store-section .terminal-store-wrapper .text-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    row-gap: 10px;
  }
  #root .card-terminal-page .terminal-store-section .terminal-store-wrapper .text-container .title-container {
    text-align: center;
  }
  #root .card-terminal-page .terminal-store-section .terminal-store-wrapper .terminal-choice-container:nth-child(1) .terminal-card-container,
  #root .card-terminal-page .terminal-store-section .terminal-store-wrapper .terminal-choice-container:nth-child(2) .terminal-card-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 30px;
  }
  #root .card-terminal-page .terminal-store-section .terminal-store-wrapper .terminal-choice-container:nth-child(1) .terminal-card-container .terminal-card,
  #root .card-terminal-page .terminal-store-section .terminal-store-wrapper .terminal-choice-container:nth-child(2) .terminal-card-container .terminal-card {
    width: 325px;
    height: 325px;
    row-gap: 37px;
  }
  #root .card-terminal-page .terminal-store-section .terminal-store-wrapper .terminal-choice-container:nth-child(1) .terminal-card-container .terminal-card img,
  #root .card-terminal-page .terminal-store-section .terminal-store-wrapper .terminal-choice-container:nth-child(2) .terminal-card-container .terminal-card img {
    width: 183px;
    height: 200px;
  }
  #root .card-terminal-page > .question-section > .question-wrapper > .question-container > .question-card-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 22px;
  }
  #root .card-terminal-page > .question-section > .question-wrapper > .customer-inquiry-container {
    padding-top: 0px;
  }
}
@media screen and (max-width: 767px) {
  #root .card-terminal-page > .store-pos-section > .store-pos-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 130px 32.5px;
  }
  #root .card-terminal-page > .store-pos-section > .store-pos-wrapper > .pos-introduction > .introduction-text {
    text-align: center;
  }
  #root .card-terminal-page > .store-pos-section > .store-pos-wrapper > .pos-introduction > .introduction-text > .title-text {
    font-size: 26px;
  }
  #root .card-terminal-page > .store-pos-section > .store-pos-wrapper > .pos-introduction > .store {
    text-align: center;
  }
  #root .card-terminal-page > .store-pos-section > .store-pos-wrapper > .pos-img-container {
    padding-top: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  #root .card-terminal-page > .store-pos-section > .store-pos-wrapper > .pos-img-container img {
    width: 328px;
    height: 291.926px;
  }
  #root .card-terminal-page > .store-pos-section > .store-pos-wrapper > .pos-img-container img:nth-child(2) {
    left: auto;
  }
  #root .card-terminal-page > .terminal-store-section > .terminal-store-wrapper {
    padding: 90px 17px;
  }
  #root .card-terminal-page > .terminal-store-section > .terminal-store-wrapper .terminal-choice-container:nth-child(1),
  #root .card-terminal-page > .terminal-store-section > .terminal-store-wrapper .terminal-choice-container:nth-child(2) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  #root .card-terminal-page > .terminal-store-section > .terminal-store-wrapper .terminal-choice-container:nth-child(1) .text-container .title,
  #root .card-terminal-page > .terminal-store-section > .terminal-store-wrapper .terminal-choice-container:nth-child(2) .text-container .title {
    font-size: 24px;
  }
  #root .card-terminal-page > .terminal-store-section > .terminal-store-wrapper .terminal-choice-container:nth-child(1) .terminal-card-container,
  #root .card-terminal-page > .terminal-store-section > .terminal-store-wrapper .terminal-choice-container:nth-child(2) .terminal-card-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 14px;
  }
  #root .card-terminal-page > .terminal-store-section > .terminal-store-wrapper .terminal-choice-container:nth-child(1) .terminal-card-container .terminal-card,
  #root .card-terminal-page > .terminal-store-section > .terminal-store-wrapper .terminal-choice-container:nth-child(2) .terminal-card-container .terminal-card {
    width: 152px;
    height: 152px;
    row-gap: 14px;
  }
  #root .card-terminal-page > .terminal-store-section > .terminal-store-wrapper .terminal-choice-container:nth-child(1) .terminal-card-container .terminal-card img,
  #root .card-terminal-page > .terminal-store-section > .terminal-store-wrapper .terminal-choice-container:nth-child(2) .terminal-card-container .terminal-card img {
    width: 87px;
    height: 95px;
  }
  #root .card-terminal-page > .terminal-store-section > .terminal-store-wrapper .terminal-choice-container:nth-child(1) .terminal-card-container .terminal-card p,
  #root .card-terminal-page > .terminal-store-section > .terminal-store-wrapper .terminal-choice-container:nth-child(2) .terminal-card-container .terminal-card p {
    font-size: 14px;
  }
  #root .card-terminal-page > .terminal-store-section > .terminal-store-wrapper .terminal-choice-container:nth-child(1) .title-container {
    width: 215px;
  }
  #root .card-terminal-page > .terminal-store-section > .terminal-store-wrapper .terminal-choice-container:nth-child(2) .title-container {
    width: 170px;
  }
  #root .card-terminal-page .question-section > .question-wrapper {
    padding: 78px 17px;
  }
  #root .card-terminal-page .question-section > .question-wrapper > .question-container > .question-card-container > .question-card {
    padding-left: 17px;
    width: 327px;
    height: 114px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    white-space: pre-wrap;
  }
  #root .card-terminal-page .question-section > .question-wrapper > .customer-inquiry-container > .customer-inquiry-button {
    width: 328px;
  }
}